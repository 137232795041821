import { acceptAllCookiesConsent, acceptConfiguredCookiesConsent } from '@redux/slices/cookies';

import type { RootState } from '@redux/store';
import type { CustomAction, CustomMiddleware } from 'types/index';

export const acceptAllCookies: CustomMiddleware<RootState> = () => (next) => (action) => {
  const result = next(action as CustomAction);

  if (acceptAllCookiesConsent.match(action)) {
    document.cookie = 'x-gdpr-facebook-pixel=true';
    document.cookie = 'x-gdpr-titkok=true';
    window.localStorage.setItem('accepted_banner_cookies', JSON.stringify(true));
  }

  return result;
};

export const acceptConfiguredCookies: CustomMiddleware<RootState> =
  (store) => (next) => (action) => {
    const result = next(action as CustomAction);

    const state = store.getState();

    if (acceptConfiguredCookiesConsent.match(action)) {
      if (state.cookies.cookies && state.cookies.cookies[4].checked) {
        document.cookie = 'x-gdpr-facebook-pixel=true';
        document.cookie = 'x-gdpr-titkok=true';
      }

      window.localStorage.setItem('accepted_banner_cookies', JSON.stringify(true));
    }

    return result;
  };

