import type { UserLocation } from '@redux/slices/user';

type CookieName = 'x-gdpr-track-always' | 'x-gdpr-facebook-pixel' | 'x-gdpr-titkok';

export function isEnvironmentValid(environment: string): boolean {
  const currentEnv = process.env.ENV || process.env.NODE_ENV || 'development';
  return environment.includes(currentEnv);
}

export function castStringToBoolean(value: string): boolean {
  return value === 'true';
}

export function getCookie(cookieName: CookieName): boolean | null {
  if (typeof document !== 'undefined') {
    const matcher = document.cookie.match('(^|;) ?' + cookieName + '=([^;]*)');
    return matcher ? castStringToBoolean(matcher[2]) : null;
  }
  return null;
}

export function getGeolocation(): Promise<UserLocation> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.GATSBY_GOOGLE_API_STATIC_MAP_KEY}`;

        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            const locationData: UserLocation = data.results[0].address_components.reduce(
              (result: UserLocation, component: { types: Array<unknown>; long_name: string }) => {
                if (component.types.includes('locality')) {
                  result.city = component.long_name;
                } else if (component.types.includes('country')) {
                  result.country = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                  result.region = component.long_name;
                }
                return result;
              },
              { city: '', country: '', region: '' }
            );
            resolve(locationData);
          })
          .catch((error) => reject(error));
      },
      (error) => {
        reject(error);
      }
    );
  });
}

