import React, { createContext, useState } from 'react';

import useAnalytics from '@hooks/useAnalytics';

type ModalContent = {
  mainComponent: JSX.Element;
  header?: JSX.Element;
  footer?: JSX.Element;
};

type OptionsModal = {
  hideScrollBar?: boolean;
};
export interface ModalContextType {
  modal: boolean;
  handleModalChange: (modalContent: ModalContent, options?: OptionsModal) => void;
  handleCloseModal: (position: string) => void;
  component: JSX.Element | null;
  header: JSX.Element | null;
  footer: JSX.Element | null;
  hideScrollBar: boolean;
}
type ContextProps = {
  children: JSX.Element | JSX.Element[];
};

export const ContextModal = createContext({} as ModalContextType);

const ModalProvider = ({ children }: ContextProps) => {
  const [modal, setModal] = useState<boolean>(false);
  const [component, setComponent] = useState<JSX.Element | null>(null);
  const [header, setHeader] = useState<JSX.Element | null>(null);
  const [footer, setFooter] = useState<JSX.Element | null>(null);
  const [hideScrollBar, setHideScrollBar] = useState(false);

  const { sendAnalytics } = useAnalytics();

  const handleModalChange = (modalContent: ModalContent, options?: OptionsModal) => {
    setModal(!modal);

    if (options && options.hideScrollBar) {
      setHideScrollBar(options.hideScrollBar);
    }
    if (children) {
      setComponent(modalContent.mainComponent);
    }
    if (modalContent.header) {
      setHeader(modalContent.header);
    }
    if (modalContent.footer) {
      setFooter(modalContent.footer);
    }
  };
  const handleCloseModal = (position: string) => {
    setModal(false);
    setHeader(null);
    setComponent(null);
    setFooter(null);
    sendAnalytics('customEvent', {
      category: 'interaction',
      action: 'close_modal',
      value: position,
    });
  };

  const value = {
    modal,
    component,
    header,
    footer,
    hideScrollBar,
    handleModalChange,
    handleCloseModal,
  };
  return <ContextModal.Provider value={value}>{children}</ContextModal.Provider>;
};

export default ModalProvider;

