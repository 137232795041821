module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Finca El Recuerdo Casa Finca Hotel","short_name":"El Recuerdo","description":"Página oficial Finca El Recuerdo - renta vacacional. Casa finca ubicada en Vergara, Cundinamarca a tan solo 90 minutos de Bogotá. Aprovecha nuestros descuentos y escapate un fin de semana a descansar en medio de las montañas.","lang":"es","start_url":"/","icon":"src/images/logos/new-logo-500x500-red-fr.png","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"05a3332dccfdb604b4ddb500cbde86d3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
