import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

export interface UserLocation {
  city: string;
  country: string;
  region: string;
}
export interface UserDevice {
  deviceType: string;
  operatingSystem: string;
  browser: {
    type: string;
    version: string;
  };
  screenResolution:
    | {
        width: number;
        height: number;
      }
    | string;
}
export interface UserPreferences {
  language: string;
  theme: string;
}
export interface User {
  userLocation: UserLocation;
  userDevice: UserDevice;
  userPreferences: UserPreferences;
}

export const initialState: User = {
  userLocation: {
    city: '',
    country: '',
    region: '',
  },
  userDevice: {
    deviceType: '',
    operatingSystem: '',
    browser: {
      type: '',
      version: '',
    },
    screenResolution: {
      width: 0,
      height: 0,
    },
  },
  userPreferences: {
    language: '',
    theme: '',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserLocation(state, action: PayloadAction<UserLocation>) {
      state.userLocation = action.payload;
    },
    getUserDeviceInfo(state, action: PayloadAction<UserDevice>) {
      state.userDevice = action.payload;
    },
    getUserPrerences(state, action: PayloadAction<UserPreferences>) {
      state.userPreferences = action.payload;
    },
  },
  selectors: {
    selectAllUserInfo: (user) => user,
    selectUserLocation: (user) => user.userLocation,
    selectUserDeviceInfo: (user) => user.userDevice,
    selectUserPreferences: (user) => user.userPreferences,
  },
});

export const { getUserLocation, getUserDeviceInfo, getUserPrerences } = userSlice.actions;

export const {
  selectUserDeviceInfo,
  selectUserLocation,
  selectUserPreferences,
  selectAllUserInfo,
} = userSlice.selectors;

export default userSlice.reducer;
