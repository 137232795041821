import React from 'react';

import type { GatsbyBrowser } from 'gatsby';

import { store } from '@redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';

import BottomSheetProvider from '@context/BottomSheetProvider';
import ModalProvider from '@context/ModalProvider';
import TooltipProvider from '@context/TooltipProvider';

const WrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}: {
  element: JSX.Element;
}) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 2,
        retryDelay: 5000,
      },
    },
  });
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BottomSheetProvider>
          <TooltipProvider>
            <ModalProvider>{element}</ModalProvider>
          </TooltipProvider>
        </BottomSheetProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default WrapRootElement;

