import { combineSlices, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import middlewares, { listenersMiddlewares } from './middlewares';
import { calendarSlice } from './slices/calendar';
import { cookiesSlice } from './slices/cookies';
import { guestsSlice } from './slices/guest';
import { mapSlice } from './slices/maps';
import { rootSlice, initialize } from './slices/root';
import { userSlice } from './slices/user';

import type { Action, ThunkAction } from '@reduxjs/toolkit';

const reducer = combineSlices(
  rootSlice,
  userSlice,
  cookiesSlice,
  guestsSlice,
  calendarSlice,
  mapSlice
);

export type RootState = ReturnType<typeof reducer>;

export const makeStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(listenersMiddlewares).concat(middlewares),
    preloadedState: preloadedState as Partial<RootState>,
  });

  setupListeners(store.dispatch);
  return store;
};

export const store = makeStore();

// Initialize Root of application
store.dispatch(initialize());

export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;

