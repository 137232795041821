/* eslint-disable prefer-rest-params */

export function addGoogleTagManager(): Promise<boolean> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return new Promise((resolve, reject) => {
    (function (w, d, s, l, i) {
      // @ts-ignore
      w[l] = w[l] || [];
      // @ts-ignore
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l != 'dataLayer' ? '&l=' + l : '';
      // @ts-ignore
      j.async = true;
      // @ts-ignore
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}&gtm_auth=${process.env.GATSBY_GTM_AUTH}&gtm_preview=${process.env.GATSBY_GTM_PREVIEW}&gtm_cookies_win=x`;

      f.parentNode && f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', process.env.GATSBY_APP_GOOGLE_TAG_MANAGER);

    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_APP_GOOGLE_TAG_MANAGER}&gtm_auth=${process.env.GATSBY_GTM_AUTH}&gtm_preview=${process.env.GATSBY_GTM_PREVIEW}&gtm_cookies_win=x`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.setAttribute('style', 'display: none; visibility: hidden');

    document.body.insertBefore(iframe, document.body.firstChild);

    window.cookiesGoogleTagManagerAdded = true;

    resolve(true);
    reject('Cannot add or initialize google tag manager');
  });
}

export function initializeGoogleTagManager(): void {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer?.push(arguments);
  };
  window.gtag('js', new Date());
  window.gtag('config', process.env.GATSBY_APP_GOOGLE_TAG_MANAGER);
  window.cookiesGoogleTagManagerInitialized = true;
}

export function trackGoogleTagManager(location: Location) {
  window.gtag('event', 'page_view', {
    page_path: `${location.pathname}${location.search}${location.hash}`,
  });
}

