import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import type { AlojamientoProps, GroupSectionKeys } from '@pages/alojamiento';
import type { Hierarchy, Page } from 'types/index';

export function isNull(value: string | number | null) {
  return value === null;
}

export function calculateReadingTime(content?: string | null) {
  if (!content) {
    return 1;
  }
  const words = content.split(' ').length;
  const minutes = Math.ceil(words / 200);
  return minutes;
}

export function capitalizeTitle(title: string) {
  return `${title.charAt(0).toUpperCase()}${title.slice(1)}`;
}

export function formatDistance(distance?: number) {
  if (!distance) {
    return 'Distancia no disponible';
  }
  return `${Math.floor(distance / 1000)} km`;
}

export function truncateText(text: string | undefined, maxLenght: number): string {
  if (!text) {
    return '';
  }
  return text && text?.length > maxLenght ? `${text.slice(0, maxLenght)}...` : text;
}

export function getHours(minutes: number | null): string {
  if (!minutes) {
    return '0 horas';
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${format(new Date(0, 0, 0, hours, remainingMinutes), 'HH:mm')} horas`;
}

export function formatAmount(amount: number | null | undefined): string | null {
  if (!amount) {
    return null;
  }
  return `${amount.toLocaleString('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })} COP`;
}

export function calculateActivityTotalAmount(
  totalOfGuests: number,
  numberOfDays: number,
  amountUnit: number | null | undefined
) {
  if (!amountUnit) {
    return null;
  }
  return totalOfGuests * numberOfDays * amountUnit;
}

export function calculateHotelTotalAmount(pricePerNight: number | null, numberOfNights: number) {
  if (!pricePerNight) {
    return null;
  }
  return pricePerNight * numberOfNights;
}

export function calculateTaxes(totalAmount: number | null) {
  if (!totalAmount) {
    return null;
  }
  return totalAmount * 0.0299 + 900;
}

export function calculateTotal(
  totalAmount: number | null,
  taxes: number | null,
  cleaningFee?: number
) {
  if (!totalAmount || !taxes) {
    return null;
  }
  if (cleaningFee) {
    return totalAmount + taxes + cleaningFee;
  }
  return totalAmount + taxes;
}

export type TypeOfFormat =
  | 'day'
  | 'long_date_month'
  | 'long_date_year'
  | 'common'
  | 'month_year'
  | 'iso';

export function formatDate(date: Date | null, typeOfFormat: TypeOfFormat) {
  if (!date) {
    return null;
  }
  switch (typeOfFormat) {
    case 'day':
      return format(date, 'd');
    case 'common':
      return format(date, 'dd/MM/yyyy');
    case 'long_date_month':
      return format(date, "d 'de' MMM'.'", { locale: es });
    case 'long_date_year':
      return format(date, "d 'de' MMM'.' 'de' yyyy", { locale: es });
    case 'month_year':
      return format(date, "MMMM 'de' yyyy", { locale: es });
    case 'iso':
      return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  }
}

export function castStringDateToDate(date: string | null) {
  if (!date) {
    return null;
  }
  new Date(date);
  return new Date(new Date(date));
}

export function firstLetterToUppercase(str: string) {
  return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
}

export function generateMessageTemplate(
  message: string,
  isMobile: boolean,
  values?: Record<string, unknown>
) {
  let newMessage = message;
  const encodedMessageWithoutParams = encodeURIComponent(
    `*Hola Finca el Recuerdo!* 👋🏻\n\n${newMessage}`
  );
  if (!values) {
    return isMobile
      ? `${process.env.GATSBY_WHATSAPP_LINK_MOBILE}${encodedMessageWithoutParams}`
      : `${process.env.GATSBY_WHATSAPP_LINK_WEB}${encodedMessageWithoutParams}`;
  }

  Object.entries(values).forEach(([key, value]) => {
    const regexString = `\\{${key}\\}`;
    const rgx = new RegExp(regexString, 'g');

    newMessage = message.replace(rgx, value as string);
  });
  const encodedMessage = encodeURIComponent(`*Hola Finca el Recuerdo!* 👋🏻\n\n${newMessage}`);
  return isMobile
    ? `${process.env.GATSBY_WHATSAPP_LINK_MOBILE}${encodedMessage}`
    : `${process.env.GATSBY_WHATSAPP_LINK_WEB}${encodedMessage}`;
}

export function redirectToWhatsApp(templateMessage: string) {
  window.open(templateMessage, '_blank', 'noopener,noreferrer');
}

export function buildHierarchyLinks(pages: Page[]): Hierarchy {
  const mainPages: Hierarchy = {
    blog: [],
    'deportes-extremos': [],
    alojamiento: [],
    fotos: [],
  };

  const addToHierarchy = (key: string, path: string) => {
    const title = path.split('/').filter(Boolean).slice(-1)[0].replace(/-/g, ' ');
    if (!mainPages[key].some((page) => page.path === path)) {
      mainPages[key].push({ path, title });
    }
  };

  pages.forEach((page) => {
    if (page.path.startsWith('/blog/')) {
      addToHierarchy('blog', page.path);
    } else if (page.path.startsWith('/deportes-extremos/')) {
      addToHierarchy('deportes-extremos', page.path);
    } else if (page.path.startsWith('/alojamiento/fotos/')) {
      addToHierarchy('fotos', page.path);
    } else if (page.path.startsWith('/alojamiento/')) {
      addToHierarchy('alojamiento', page.path);
    }
  });

  return mainPages;
}

export type Groups = AlojamientoProps['data']['hotelPhotos'];

export function filterPhotosGruopByKey(groups: Groups, key: GroupSectionKeys) {
  if (!groups || !groups.photos) {
    return [];
  }
  return groups.photos.filter((group) => group?.key?.includes(key));
}

