import { setCookies, toogleCookiesConsent } from '@redux/slices/cookies';
import { initialize } from '@redux/slices/root';

import { isBrowser } from '@helpers/isBrowser';

import type { RootState } from '@redux/store';
import type { CustomAction, CustomMiddleware } from 'types/index';

export const showDelayBanner: CustomMiddleware<RootState> = (store) => (next) => (action) => {
  let timeoutId: NodeJS.Timeout | null = null;

  const result = next(action as CustomAction);
  const state = store.getState();
  if (isBrowser() && initialize.match(action) && !state.cookies.showCookiesConsent) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      const cookiesBannerAcceptedFromLS = window.localStorage.getItem('accepted_banner_cookies');

      const cookiesBannerAccepted = cookiesBannerAcceptedFromLS
        ? JSON.parse(cookiesBannerAcceptedFromLS)
        : false;

      if (!cookiesBannerAccepted) {
        store.dispatch(toogleCookiesConsent(true));
        store.dispatch(setCookies());
      }
    }, 10000);
  }

  return result;
};

