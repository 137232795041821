import { getDeviceInfo, reduceUserLocationData } from '@redux/helpers';
import { getUserLocation, getUserDeviceInfo, getUserPrerences } from '@redux/slices/user';

import { isBrowser } from '@helpers/isBrowser';

import type { RootState } from '@redux/store';
import type { Middleware } from '@reduxjs/toolkit';

let userDataFullfilled = false;

export const getUserInformation: Middleware<object, RootState> = (store) => (next) => (action) => {
  const state = store.getState();
  if (!userDataFullfilled) {
    userDataFullfilled = true;
    const userLocationDoesntExist = Object.values(state.user.userLocation).every(
      (value) => value === ''
    );
    const userPreferences = Object.values(state.user.userPreferences).every(
      (value) => value === ''
    );
    if (isBrowser() && navigator.geolocation && userLocationDoesntExist) {
      navigator.geolocation.getCurrentPosition((position) => {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          if (result.state === 'granted') {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.GATSBY_GOOGLE_API_STATIC_MAP_KEY}`;

            fetch(url)
              .then((res) => res.json())
              .then((data) => {
                const locationData = reduceUserLocationData(data);
                store.dispatch(getUserLocation(locationData));
              })
              .catch((error) => {
                throw new Error(error);
              });
          } else if (result.state === 'denied') {
            throw new Error('Permission geolocation has been denied');
          }
        });
      });
    }
    if (isBrowser() && navigator.language && userPreferences) {
      store.dispatch(getUserPrerences({ language: navigator.language, theme: 'light' }));
    }
    if (isBrowser()) {
      const deviceInfo = getDeviceInfo();
      store.dispatch(
        getUserDeviceInfo({
          ...deviceInfo,
          screenResolution: { height: window.screen.height, width: window.screen.width },
        })
      );
    }
  }
  return next(action);
};

