import React, { createContext, useState } from 'react';

import useWindowSize from '@hooks/useWindowSize';

import type { PanInfo } from 'framer-motion';

type BottomSheetComponent = {
  mainComponent: JSX.Element;
  header?: JSX.Element;
  footer?: JSX.Element;
};
export interface BottomSheetContextType {
  toggleSheet: boolean;
  grow: boolean;
  component: JSX.Element | null;
  header: JSX.Element | null;
  footer: JSX.Element | null;
  height: number;
  openFull: boolean;
  handleOpenBottomSheet: (bottomSheetComponent: BottomSheetComponent, full?: boolean) => void;
  handleCloseBottomSheet: ({ callback }: { callback?: () => void }) => void;
  handleOnDrag: (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void;
}
type ContextProps = {
  children: JSX.Element | JSX.Element[];
};

export const ContextBottomSheet = createContext({} as BottomSheetContextType);

const BottomSheetProvider = ({ children }: ContextProps) => {
  const [toggleSheet, setToggleSheet] = useState<boolean>(false);
  const [component, setComponent] = useState<JSX.Element | null>(null);
  const [header, setHeader] = useState<JSX.Element | null>(null);
  const [footer, setFooter] = useState<JSX.Element | null>(null);
  const [openFull, setOpenFull] = useState(false);
  const [grow, setGrow] = useState(false);
  const { windowSize } = useWindowSize();

  const height = windowSize.height;

  const handleOpenBottomSheet = (bottomSheetComponent: BottomSheetComponent, full?: boolean) => {
    setToggleSheet(!toggleSheet);
    if (full) {
      setOpenFull(full);
    }
    if (bottomSheetComponent.mainComponent) {
      setComponent(bottomSheetComponent.mainComponent);
    }
    if (bottomSheetComponent.header) {
      setHeader(bottomSheetComponent.header);
    }
    if (bottomSheetComponent.footer) {
      setFooter(bottomSheetComponent.footer);
    }
  };

  const handleCloseBottomSheet = ({ callback }: { callback?: () => void }) => {
    if (typeof callback === 'function') {
      callback();
    }
    setOpenFull(false);
    setToggleSheet(false);
    setComponent(null);
    setHeader(null);
    setFooter(null);
    setGrow(false);
  };

  const handleOnDrag = (_: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    if (info.offset.y > 50) {
      setOpenFull(false);
      setToggleSheet(false);
      setGrow(false);
      setComponent(null);
      setHeader(null);
      setFooter(null);
    }
    if (info.offset.y < -50) {
      setGrow(true);
    }
  };

  const value = {
    toggleSheet,
    component,
    header,
    footer,
    grow,
    height,
    openFull,
    handleOpenBottomSheet,
    handleCloseBottomSheet,
    handleOnDrag,
  };
  return <ContextBottomSheet.Provider value={value}>{children}</ContextBottomSheet.Provider>;
};

export default BottomSheetProvider;

