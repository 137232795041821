import type { ErrorResponse } from 'types/index';

export const defineErrorGoogleMaps = (
  status: google.maps.DirectionsStatus | string | null
): ErrorResponse => {
  const timestamp = new Date().toISOString();
  switch (status) {
    case google.maps.DirectionsStatus.NOT_FOUND:
      return {
        status: 515,
        message:
          'El origen o la ruta que ingresaste no puede ser encontrada, por favor intenta de nuevo.',
        errorCode: google.maps.DirectionsStatus.NOT_FOUND,
        details: 'Google maps exception',
        timestamp,
        path: 'https://maps.googleapis.com/',
      };
    case google.maps.DirectionsStatus.INVALID_REQUEST:
      return {
        status: 515,
        message: 'Tu solicitud fue invalida, revisa la dirección de origen e intenta de nuevo.',
        errorCode: google.maps.DirectionsStatus.INVALID_REQUEST,
        details: 'Google maps exception',
        timestamp,
        path: 'https://maps.googleapis.com/',
      };
    case google.maps.DirectionsStatus.OVER_QUERY_LIMIT:
      return {
        status: 515,
        message:
          'Superaste el número de solicitudes máximas permitadas, por favor intenta más tarde.',
        errorCode: google.maps.DirectionsStatus.OVER_QUERY_LIMIT,
        details: 'Google maps exception',
        timestamp,
        path: 'https://maps.googleapis.com/',
      };
    case google.maps.DirectionsStatus.REQUEST_DENIED:
      return {
        status: 515,
        message: 'No tienes permitido realizar esta acción, por favor intenta más tarde.',
        errorCode: google.maps.DirectionsStatus.REQUEST_DENIED,
        details: 'Google maps exception',
        timestamp,
        path: 'https://maps.googleapis.com/',
      };
    case google.maps.DirectionsStatus.MAX_WAYPOINTS_EXCEEDED:
      return {
        status: 515,
        message: 'La ruta no puede ser mostrada de acuerdo al origen que indicaste.',
        errorCode: google.maps.DirectionsStatus.MAX_WAYPOINTS_EXCEEDED,
        details: 'Google maps exception',
        timestamp,
        path: 'https://maps.googleapis.com/',
      };
    case google.maps.DirectionsStatus.UNKNOWN_ERROR:
    case google.maps.DirectionsStatus.ZERO_RESULTS:
      return {
        status: 515,
        message: 'La ruta que solicitas no arrojó ningún resultado, intenta más tarde.',
        errorCode:
          google.maps.DirectionsStatus.UNKNOWN_ERROR ?? google.maps.DirectionsStatus.ZERO_RESULTS,
        details: 'Google maps exception',
        timestamp,
        path: 'https://maps.googleapis.com/',
      };
    case null:
    default:
      return {
        status: 515,
        message: 'Algo inesperado pasó',
        errorCode: 'INTERNAL_SERVER_ERROR',
        details: 'Google maps exception',
        timestamp,
        path: 'https://maps.googleapis.com/',
      };
  }
};

