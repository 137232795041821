import React from 'react';

import { IoIosClose } from '@react-icons/all-files/io/IoIosClose';
import { AnimatePresence, motion } from 'framer-motion';

import Button from '@components/buttons/Button';

import useBottomSheet from '@hooks/useBottomSheet';

const BottomSheet = () => {
  const {
    toggleSheet,
    component,
    header,
    footer,
    grow,
    height,
    openFull,
    handleCloseBottomSheet,
    handleOnDrag,
  } = useBottomSheet();

  /** Helps to reset dates to default in case that user close the bottom sheet wihtout any dates */

  return (
    <AnimatePresence mode='wait'>
      {toggleSheet && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`md:hidden fixed top-0 left-0 z-[100] bg-stone-900/60 w-full h-full`}
          onClick={() => handleCloseBottomSheet({})}
        >
          <motion.div
            data-testid='container-bottom-sheet'
            drag='y'
            onDrag={handleOnDrag}
            dragElastic={0}
            dragConstraints={{ top: 0 }}
            dragMomentum={false}
            initial={{ translateY: height }}
            animate={grow ? { translateY: 0 } : { translateY: openFull ? 0 : 450 }}
            exit={{ translateY: height }}
            transition={{ type: 'tween', ease: 'easeInOut' }}
            className={`bg-white fixed bottom-0 rounded-t-2xl w-full`}
            style={{ height }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className='flex justify-center'>
              <div className='bg-gray-300 h-1 mt-5 rounded-full w-[50px]' />
            </div>
            <div className='pl-3'>
              <Button
                type='button'
                data-testid='close-button'
                title=''
                Icon={IoIosClose}
                variant='inline'
                onClick={() => handleCloseBottomSheet({})}
              />
            </div>
            <div>
              {header && (
                <div className='border-b-[1px]'>
                  <div className='px-5'>{header}</div>
                </div>
              )}
              <div
                className='overflow-auto px-5 py-2'
                style={{ height: footer ? height - 170 : height - 50 }}
              >
                {component}
              </div>
              {footer && (
                <div className='fixed bottom-0 border-t-[1px] mb-1 h-auto py-2 w-full z-50 bg-white'>
                  <div className='px-5'>{footer}</div>
                </div>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BottomSheet;

