import { createSlice } from '@reduxjs/toolkit';

import { cookiesDisclaimer } from '@data/cookiesDisclamer';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { CookiesDisclaimer } from 'types/index';

export interface Cookies {
  showCookiesConsent: boolean;
  acceptAllCookiesConsent: boolean;
  acceptConfiguredCookiesConsent: boolean;
  cookies: Array<CookiesDisclaimer> | null;
}

export const initialState: Cookies = {
  showCookiesConsent: false,
  acceptAllCookiesConsent: false,
  acceptConfiguredCookiesConsent: false,
  cookies: null,
};

export const cookiesSlice = createSlice({
  name: 'cookies',
  initialState,
  reducers: {
    toogleCookiesConsent(state, action: PayloadAction<boolean>) {
      state.showCookiesConsent = action.payload;
    },
    acceptAllCookiesConsent(state, action: PayloadAction<boolean>) {
      state.acceptAllCookiesConsent = action.payload;
    },
    acceptConfiguredCookiesConsent(state, action: PayloadAction<boolean>) {
      state.acceptConfiguredCookiesConsent = action.payload;
    },
    setCookies(state) {
      state.cookies = cookiesDisclaimer;
    },
    setIndividualCookie(state, action: PayloadAction<{ id: number; checked: boolean }>) {
      const newCookies =
        state.cookies?.map((cookie) => {
          return action.payload.id === cookie.id
            ? { ...cookie, checked: action.payload.checked }
            : cookie;
        }) ?? null;
      state.cookies = newCookies;
    },
  },
  selectors: {
    selectShowCookiesConsent: (cookies) => cookies.showCookiesConsent,
    selectAcceptConsent: (cookies) => cookies.acceptAllCookiesConsent,
    selectCookies: (cookies) => cookies.cookies,
  },
});

export const {
  toogleCookiesConsent,
  acceptAllCookiesConsent,
  acceptConfiguredCookiesConsent,
  setCookies,
  setIndividualCookie,
} = cookiesSlice.actions;

export const { selectShowCookiesConsent, selectAcceptConsent, selectCookies } =
  cookiesSlice.selectors;

export default cookiesSlice.reducer;

