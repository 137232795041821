import React from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import useShowTooltip from '@hooks/useShowTooltip';

const Tooltip = () => {
  const { showTooltip, component } = useShowTooltip();

  return (
    <AnimatePresence mode='wait'>
      {showTooltip && (
        <motion.div
          initial={{ opacity: 0, y: 100, x: '-50%' }}
          animate={{ opacity: 1, y: -10, x: '-50%' }}
          exit={{ y: 100 }}
          transition={{ type: 'tween' }}
          className='bg-white flex gap-2 items-center shadow-lg py-2 px-2 text-[1rem] rounded-lg z-[999] fixed left-1/2 bottom-1'
        >
          {component}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Tooltip;

