import { getRoute, routeError, routeSuccess } from '@redux/slices/maps';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import { isBrowser } from '@helpers/isBrowser';

import { defineErrorGoogleMaps } from './helpers';

const googleMapsListenerMiddleware = createListenerMiddleware();

googleMapsListenerMiddleware.startListening({
  actionCreator: getRoute,
  effect: async (_, listenerApi) => {
    const directionsRequest: google.maps.DirectionsRequest = {
      origin: '',
      destination: '5.096436, -74.357216',
      travelMode: 'DRIVING' as google.maps.TravelMode,
      optimizeWaypoints: true,
      language: 'es-419',
      region: 'CO',
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidFerries: true,
      avoidHighways: false,
      avoidTolls: false,
    };

    if (isBrowser()) {
      const origin = (listenerApi.getState() as any).map.route.origin;
      const request = { ...directionsRequest, origin: origin };

      const directionsService = new google.maps.DirectionsService();
      if (directionsService) {
        try {
          await directionsService.route(request, (result, status: google.maps.DirectionsStatus) => {
            if (status !== google.maps.DirectionsStatus.OK) {
              const error = defineErrorGoogleMaps(status);
              listenerApi.dispatch(routeError(error));
              return;
            }

            if (status === google.maps.DirectionsStatus.OK) {
              listenerApi.dispatch(routeSuccess(result));
            }
          });
        } catch (error) {
          console.error(error);
          throw new Error(error as string);
        }
      }
    }
  },
});

export { googleMapsListenerMiddleware };

