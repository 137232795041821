import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { ErrorResponse } from 'types/index';


export interface OriginPayload {
  origin: string;
}

export type DirectionsResultGoogleMap = google.maps.DirectionsResult | null;
export type DirectionsRendered = google.maps.DirectionsRenderer | null;

export interface Map {
  route: {
    origin: string | null;
    data: DirectionsResultGoogleMap;
    loading: boolean;
    error: ErrorResponse | null;
  };
}
export const initialState: Map = {
  route: {
    origin: null,
    data: null,
    loading: false,
    error: null,
  },
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    getRoute(state, action: PayloadAction<OriginPayload>) {
      state.route.data = null;
      state.route.loading = true;
      state.route.error = null;
      state.route.origin = action.payload.origin;
    },
    routeSuccess(state, action: PayloadAction<Map['route']['data']>) {
      state.route.loading = false;
      state.route.data = action.payload;
    },
    routeError(state, action: PayloadAction<Map['route']['error']>) {
      state.route.loading = false;
      state.route.data = null;
      state.route.error = action.payload;
    },
    reset(state) {
      state.route = initialState.route;
    },
  },
  selectors: {
    selectLoading: (map) => map.route.loading,
    selectRouteData: (map) => map.route.data,
    selectSummaryRoute: (map) => map.route.data?.routes[0].summary,
    selectError: (map) => map.route.error,
  },
});

export const { getRoute, routeSuccess, routeError, reset } = mapSlice.actions;

export const { selectSummaryRoute, selectRouteData, selectLoading, selectError } =
  mapSlice.selectors;

export default mapSlice.reducer;

