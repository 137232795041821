import { addFacebookPixel, initilizeFacebookPixel, trackFacebookPixel } from './facebookPixel';
import {
  addGoogleTagManager,
  initializeGoogleTagManager,
  trackGoogleTagManager,
} from './googleTagManager';
import { getCookie } from './helpers';

export function initializeAndTrackGooogleTagManager(location: Location) {
  addGoogleTagManager().then((status) => {
    if (status) {
      initializeGoogleTagManager();
      trackGoogleTagManager(location);
    }
  });
}

export function initializeAndTrackFacebookPixel(
  location: Location,
  trackingId: string | undefined
) {
  if (!trackingId) {
    return;
  }
  addFacebookPixel().then((status) => {
    if (status) {
      initilizeFacebookPixel(trackingId);
      trackFacebookPixel(location);
    }
  });
}

/** @function initializeAndTrack start all services */

export function initializeAndTrack(location: Location | null) {
  if (location === undefined || location === null) {
    console.error('Location undefined');
    return;
  } else {
    const alwaysTrack = getCookie('x-gdpr-track-always');
    const facebookTrack = getCookie('x-gdpr-facebook-pixel');
    if (alwaysTrack) {
      initializeAndTrackGooogleTagManager(location);
    }
    if (facebookTrack) {
      initializeAndTrackFacebookPixel(location, process.env.GATSBY_APP_FACEBOOK_PIXEL);
    }
  }
}

