import type { CookiesDisclaimer } from 'types/index';

export const cookiesDisclaimer: Array<CookiesDisclaimer> = [
  {
    id: 1,
    title: 'Cookies de targeting',
    content:
      'Estas cookies pueden ser configuradas por nuestros socios publicitarios. Ellos probablemente usen esta información para construir perfiles con base en tus intereses y mostrar publicidad relevante en sus sitios. Por ningún motivo se almacena información personal, solamente se basan en tu actividad en el explorador web y tu dispositivo. Si no aceptas estas cookies experimentarás un aumento en publicidad no dirigida hacia ti.',
    checked: true,
  },
  {
    id: 2,
    title: 'Cookies de performance',
    content:
      'Estas cookies nos permitn contar tu visita a nuestro sitio web, como el tráfico de diferentes fuentes para medir y mejorar nuestro desempeño en el sitio web. Estas cookies nos ayudan a saber que páginas son las que más ves y cuántos visitantes se mueven dentro del sitio web. Toda la información de éstas cookies son recolectadas y agregadas de forma anónima. Si no aceptas estas cookies no podremos monitorear tu actividad dentro del sitio web y puedes esperar un desempeño no adecuado cuándo navegas por el mismo.',
    checked: true,
  },
  {
    id: 3,
    title: 'Cookies estrictas',
    content:
      'Estas cookies son necesarias para que el sitio web funcione y no pueden ser modificadas por el usuario. Ellas usualmente se encuentran relacionadas con las modificaciones que realizas, así como la configuración de propiedades del usuario o el diligenciamiento de formularios. Puedes configurar tu explorador para bloquear estas cookies, pero algunas partes del sitio no funcionarán. Estas cookies no almacenan ningún tipo de información personal.',
    checked: true,
  },
  {
    id: 4,
    title: 'Cookies funcionales',
    content:
      'Estas cookies habilitan la funcionalidad del sitio y la personalización. Tal vez puedan ser configuradas por proveedores o terceros cuyos servicios son agregados a nuestra página. Si no aceptas estas cookies entonces algunos de nuestros servicios no funcionarán correctamente.',
    checked: true,
  },
  {
    id: 5,
    title: 'Cookies redes sociales',
    content:
      'Estas cookies configuran un rango de servicios ofrecidos por redes sociales que se pueden agregar al sitio para habilitar compartir contenido con tus amigos en la red. Son capaces de rastrear tu actividad a través de otros sitios y construir un perfil basado en tus intereses. Eso puede impactar en los contenidos y mensajes que tu ves en otros sitios web. Si no aceptas estas cookies puede que no veas o puedas usar herramientas para compartir contenido.',
    checked: true,
  },
];
