import React from 'react';

import { IoIosClose } from '@react-icons/all-files/io/IoIosClose';
import { AnimatePresence, motion } from 'framer-motion';

import useModal from '@hooks/useModal';

import Button from './buttons/Button';

const Modal = () => {
  const { modal, header, footer, handleCloseModal, component, hideScrollBar } = useModal();
  return (
    <AnimatePresence mode='wait'>
      {modal && (
        <motion.div
          className='fixed top-0 left-0 w-full h-full flex justify-center items-center bg-stone-900/50 z-[100] backdrop-blur-sm'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'tween' }}
          onClick={() => handleCloseModal('out of modal')}
        >
          <motion.div
            initial={{ y: -200 }}
            animate={{ y: 0 }}
            exit={{ y: 200 }}
            transition={{ type: 'tween' }}
            className='bg-white rounded-md shadow-xl p-7 max-w-[450px] xl:max-w-[650px]'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='flex justify-end'>
              <Button
                type='button'
                title=''
                variant='inline'
                Icon={IoIosClose}
                iconSize={30}
                onClick={() => handleCloseModal('close button modal')}
              />
            </div>
            <div>{header}</div>
            <div
              className={`${!hideScrollBar && 'md:max-h-[450px] xl:max-h-[500px] overflow-auto'}`}
            >
              {component}
            </div>
            <div>{footer}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;

