import { createSlice } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';

import { formatDate } from '@helpers/global';

import type { PayloadAction } from '@reduxjs/toolkit';

export enum FocusType {
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  GUEST_SELECTOR = 'guest_selector',
}

export interface SelectedDates {
  start: string | null;
  end: string | null;
}
export interface Calendar {
  selectedDates: SelectedDates;
  focusType: FocusType | null;
}

export const initialState: Calendar = {
  selectedDates: {
    start: formatDate(new Date(), 'iso'),
    end: formatDate(addDays(new Date(), 1), 'iso'),
  },
  focusType: null,
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    updateDates: (state, action: PayloadAction<SelectedDates>) => {
      state.selectedDates.start = action.payload.start;
      state.selectedDates.end = action.payload.end;
    },
    updateFocus: (state, action: PayloadAction<FocusType>) => {
      state.focusType = action.payload;
    },
  },
  selectors: {
    selectSelectedDates: (state) => state.selectedDates,
    selectFocus: (state) => state.focusType,
  },
});

export const { updateDates, updateFocus } = calendarSlice.actions;

export const { selectSelectedDates, selectFocus } = calendarSlice.selectors;

export default calendarSlice.reducer;
