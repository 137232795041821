import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    initialize(state) {
      state;
    },
  },
});

export const { initialize } = rootSlice.actions;

