/* eslint-disable prefer-rest-params */

export function addFacebookPixel(): Promise<boolean> {
  /* eslint-disable */

  // @typescript-eslint/no-unused-vars
  return new Promise((resolve, reject) => {
    (function (f, b, e, v, n, t, s) {
      // @ts-ignore
      if (f.fbq) return;
      // @ts-ignore
      n = f.fbq = function () {
        // @ts-ignore
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      // @ts-ignore
      if (!f._fbq) f._fbq = n;
      // @ts-ignore
      n.push = n;
      // @ts-ignore
      n.loaded = !0;
      // @ts-ignore
      n.version = '2.0';
      // @ts-ignore
      n.queue = [];
      // @ts-ignore
      t = b.createElement(e);
      // @ts-ignore
      t.async = !0;
      // @ts-ignore
      t.src = v;
      // @ts-ignore
      s = b.getElementsByTagName(e)[0];
      // @ts-ignore
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    const img = document.createElement('img');
    img.src = `https://www.facebook.com/tr?id=${process.env.GATSBY_APP_FACEBOOK_PIXEL}&ev=PageView&noscript=1`;
    img.height = 1;
    img.width = 1;
    img.setAttribute('style', 'display: none;');
    resolve(true);
    reject('Cannot load the facebook pixel');
  });
}

export function initilizeFacebookPixel(trackingId: string) {
  window.fbq('init', trackingId);
}

export function trackFacebookPixel(location: Location) {
  window.fbq('track', location);
}

