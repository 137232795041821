import "@fontsource/poppins";
import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/900.css";

import { addAdsense } from "@config/configAdsense/configAdsense";

import WrapPageElement from "./src/components/WrapPageElement";
import WrapRootElement from "./src/components/WrapRootElement";
import { initializeAndTrack } from './src/config/configCookies';
// import { initializeAndAddFacebookSDK } from './src/config/configFbSDK'
import "./src/styles/globals.css";

import type { WindowLocation } from "@reach/router";


export const onRouteUpdate = ({ location }: { location: WindowLocation }) => {
  /** @cookie strict, always trigger */
  document.cookie = 'x-gdpr-track-always=true';
  initializeAndTrack(location)
  // initializeAndAddFacebookSDK()
}

export const onClientEntry = () => {
  addAdsense()
}
export const wrapPageElement = WrapPageElement;

export const wrapRootElement = WrapRootElement;
