import { acceptAllCookies, acceptConfiguredCookies } from './acceptCookies';
import { getUserInformation } from './getUserInformation';
import { googleMapsListenerMiddleware } from './GoogleMaps';
import { showDelayBanner } from './showDelayBanner';

export default [getUserInformation, showDelayBanner, acceptAllCookies, acceptConfiguredCookies];

export const listenersMiddlewares = [googleMapsListenerMiddleware].map(
  (listener) => listener.middleware
);

