import React from 'react';

import BottomSheet from './bottomSheet/BottomSheet';
import Modal from './Modal';
import Tooltip from './tooltips/Tooltip';

interface Props {
  element: JSX.Element;
}

const WrapPageElement = ({ element }: Props) => {
  return (
    <>
      {element}
      <Modal />
      <BottomSheet />
      <Tooltip />
    </>
  );
};

export default WrapPageElement;

