import type { UserLocation } from '@redux/slices/user';

interface AddressComponent {
  address_components: Array<{ types: Array<string>; long_name: string }>;
}

interface GoogleLocation {
  results: Array<AddressComponent>;
}

export function reduceUserLocationData(data: GoogleLocation) {
  return data.results[0].address_components.reduce(
    (result: UserLocation, component: { types: Array<unknown>; long_name: string }) => {
      if (component.types.includes('locality')) {
        result.city = component.long_name;
      } else if (component.types.includes('country')) {
        result.country = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        result.region = component.long_name;
      }
      return result;
    },
    { city: '', country: '', region: '' }
  );
}

export function getDeviceInfo() {
  const userAgent = navigator.userAgent;
  const deviceInfo = {
    deviceType: 'Unknown',
    operatingSystem: 'Unknown',
    browser: {
      type: 'Unknown',
      version: 'Unknown',
    },
  };

  if (/Windows NT/.test(userAgent)) {
    deviceInfo.operatingSystem = 'Windows';
  } else if (/Macintosh/.test(userAgent)) {
    deviceInfo.operatingSystem = 'MacOS';
    if ('ontouchend' in document) {
      deviceInfo.deviceType = 'iPad (iPadOS)';
    } else {
      deviceInfo.deviceType = 'Mac';
    }
  } else if (/iPhone/.test(userAgent)) {
    deviceInfo.operatingSystem = 'iOS';
    deviceInfo.deviceType = 'iPhone';
  } else if (/iPad/.test(userAgent)) {
    deviceInfo.operatingSystem = 'iOS';
    deviceInfo.deviceType = 'iPad';
  } else if (/Android/.test(userAgent)) {
    deviceInfo.operatingSystem = 'Android';
    deviceInfo.deviceType = 'Mobile';
  } else if (/Linux/.test(userAgent)) {
    deviceInfo.operatingSystem = 'Linux';
  }

  const browserMatch =
    userAgent.match(/(chrome|safari|firefox|msie|trident|edge(?=\/))\/?\s*(\d+)/i) || [];
  let temp;

  if (/trident/i.test(browserMatch[1])) {
    temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    deviceInfo.browser.type = 'IE';
    deviceInfo.browser.version = temp[1] || '';
  } else if (browserMatch[1] === 'Chrome') {
    temp = userAgent.match(/\b(OPR|Edge?)\/(\d+)/);
    if (temp != null) {
      deviceInfo.browser.type = temp.slice(1)[0].replace('OPR', 'Opera');
      deviceInfo.browser.version = temp[1];
    } else {
      deviceInfo.browser.type = 'Chrome';
      deviceInfo.browser.version = browserMatch[2];
    }
  } else {
    browserMatch[1] = browserMatch[1] || '';
    browserMatch[2] = browserMatch[2] || '';
    deviceInfo.browser.type = browserMatch[1];
    deviceInfo.browser.version = browserMatch[2];
  }

  if (deviceInfo.browser.type.toLowerCase() === 'safari' && !/chrome/i.test(userAgent)) {
    const versionMatch = userAgent.match(/version\/(\d+)/i);
    if (versionMatch) {
      deviceInfo.browser.version = versionMatch[1];
    }
  }

  return deviceInfo;
}
