import React from 'react';

import classNames from 'classnames';

import { firstLetterToUppercase } from '@helpers/global';

import type { IconType } from '@react-icons/all-files/lib';

export type ButtonVariants =
  | 'main'
  | 'secondary'
  | 'danger'
  | 'info'
  | 'warning'
  | 'inline'
  | 'icon';

type MainBtnProps = {
  title: string;
  variant: ButtonVariants;
  disabled?: boolean;
  Icon?: IconType;
  type: 'button' | 'submit';
  className?: string;
  fullWidth?: boolean;
  iconSize?: number;
};

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & MainBtnProps;

const Button = ({
  title,
  variant = 'main',
  disabled = false,
  Icon,
  type = 'button',
  fullWidth,
  iconSize,
  ...props
}: ButtonProps): JSX.Element => {
  const buttonStyles = classNames(
    `text-base rounded-xl transition ease-in-out duration-500 
    disabled:cursor-pointer-none disabled:text-slate-400 
    disabled:opacity-60 disabled:transform-none disabled:pointer-events-none 
    disabled:cursor-default disabled:bg-gray-100`,
    {
      'px-6 h-[45px]': variant !== 'inline',
      'bg-main-500 hover:bg-main-900 text-white': variant === 'main',
      'border border-[2px] border-main-500 hover:border-main-700 text-main-500 hover:text-main-700':
        variant == 'secondary',
      'p-0 text-slate-600 underline': variant === 'inline',
      'text-main bg-red-300 hover:bg-red-400 text-main-500 hover:text-main-700':
        variant === 'danger',
      'bg-sky-300 hover:bg-sky-400 text-sky-800': variant === 'info',
      'bg-amber-100 hover:bg-amber-200 text-amber-900': variant === 'warning',
      'w-full': fullWidth,
    }
  );

  const textStyles = classNames({
    hidden: Icon,
  });

  const iconStyles = classNames('', {
    'flex gap-2 items-center': title !== '',
  });

  return (
    <button
      {...props}
      name={title}
      type={type}
      disabled={disabled}
      className={`${buttonStyles} ${props.className ?? ''}`}
    >
      {Icon ? (
        <div className={iconStyles}>
          {Icon && <Icon size={iconSize ?? 20} />}
          <div>{firstLetterToUppercase(title)}</div>
        </div>
      ) : (
        <div className={textStyles}>{firstLetterToUppercase(title)}</div>
      )}
    </button>
  );
};

export default Button;

