import { createSlice } from '@reduxjs/toolkit';

export interface Guest {
  adults: number;
  childrens: number;
  pets: number;
  totalGuests: number;
}

export const initialState: Guest = {
  adults: 1,
  childrens: 0,
  pets: 0,
  totalGuests: 1,
};

export const guestsSlice = createSlice({
  name: 'guests',
  initialState,
  reducers: {
    addAdult(state) {
      state.adults = state.adults + 1;
      state.totalGuests = state.totalGuests + 1;
    },
    removeAdult(state) {
      state.adults = state.adults - 1;
      state.totalGuests = state.totalGuests - 1;
    },
    addChildren(state) {
      state.childrens = state.childrens + 1;
      state.totalGuests = state.totalGuests + 1;
    },
    removeChildren(state) {
      state.childrens = state.childrens - 1;
      state.totalGuests = state.totalGuests - 1;
    },
    addPet(state) {
      state.pets = state.pets + 1;
    },
    removePet(state) {
      state.pets = state.pets - 1;
    },
  },
  selectors: {
    selectAdults: (guests) => guests.adults,
    selectChildrens: (guests) => guests.childrens,
    selectPets: (guests) => guests.pets,
    selectTotalGuests: (guests) => guests.totalGuests,
  },
});

export const { addAdult, removeAdult, addChildren, removeChildren, addPet, removePet } =
  guestsSlice.actions;

export const { selectAdults, selectChildrens, selectPets, selectTotalGuests } =
  guestsSlice.selectors;

export default guestsSlice.reducer;

