import React, { createContext, useEffect, useState } from 'react';

export interface TooltipContextType {
  showTooltip: boolean;
  handleShowTooltip: (showTooltip: boolean, children: JSX.Element) => void;
  component: JSX.Element | null;
}
type ContextProps = {
  children: JSX.Element | JSX.Element[];
};

export const TooltipContext = createContext({} as TooltipContextType);

const TooltipProvider = ({ children }: ContextProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [component, setComponent] = useState<JSX.Element | null>(null);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setShowTooltip(false);
    }, 2000);

    return () => window.clearTimeout(timer);
  }, [showTooltip]);

  const handleShowTooltip = (showTooltip: boolean, children?: JSX.Element) => {
    setShowTooltip(showTooltip);
    if (children) {
      setComponent(children);
    }
  };

  const value = { showTooltip, component, handleShowTooltip };
  return <TooltipContext.Provider value={value}>{children}</TooltipContext.Provider>;
};

export default TooltipProvider;
