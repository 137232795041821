import { useAppSelector } from '@redux/hooks';
import { selectAllUserInfo } from '@redux/slices/user';

import type { DataLayerEventName, DataLayerObject, EventData } from 'types/tag-manager-types';

const useAnalytics = () => {
  const { userDevice, userLocation, userPreferences } = useAppSelector(selectAllUserInfo);
  const sendAnalytics = (event: DataLayerEventName, eventData: EventData): number | undefined =>
    window.dataLayer?.push({
      event,
      userData: {
        userDevice,
        userLocation,
        userPreferences,
      },
      eventData,
    } as DataLayerObject);
  return { sendAnalytics };
};

export default useAnalytics;

